import type { RuntimeHost, Xinglet } from '@xing-com/crate-xinglet';

export default class RootXinglet implements Xinglet {
  public getComponent(
    host: RuntimeHost
  ): React.ComponentType<{ basePath: string }> {
    return ({ basePath }) => {
      return (
        <host.XingletLoader
          name="@xing-com/crate-communication-root"
          baseSite="xing-login"
          basePath={basePath}
        />
      );
    };
  }
}
